<template>
  <div>
    <VTitle :title="$t('app.add_registration')" class="section-title" />

    <Form
      :is-loading="isLoading"
      @click:cancel="onClickCancel"
      @click:save="onSave"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useRegistrationCollection from "@/composables/useRegistrationCollection";
import useCreate from "@/composables/useCreate";
// Components
import VTitle from "@/components/VTitle";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.add")} - ${t("app.registrations", 2)} - ${t("app.teaching")}`;

    // COMPOSABLES
    const { endpoint, route } = useRegistrationCollection();
    const { isLoading, onClickSave, onClickCancel } = useCreate(
      endpoint,
      route
    );

    // Methods
    const onSave = async data => {
      if (!data?.completion_deadline) {
        delete data.completion_deadline;
      }

      await onClickSave(data);
    };

    return {
      documentTitle,
      onSave,
      // Create
      isLoading,
      onClickCancel
    };
  }
};
</script>
