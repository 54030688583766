<template>
  <FormCard
    :is-loading="isLoading"
    @click:cancel="onCancel"
    @click:save="onSave"
  >
    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-6 mb-6">
        <VSelect
          v-model="v.user_id.$model"
          :label="$t('app.learners')"
          :errors="v.user_id.$errors"
          :options="options.user_id"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VSelect
          v-model="v.collection_id.$model"
          :label="$t('app.collection_id')"
          :errors="v.collection_id.$errors"
          :options="options.collection_id"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VSelect
          v-model="v.status.$model"
          :label="$t('app.status')"
          :errors="v.status.$errors"
          :options="options.status"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <!-- <VSelect
          v-model="v.registered_via.$model"
          :label="$t('app.registered_via')"
          :errors="v.registered_via.$errors"
          :options="registeredViaOptions"
          :readonly="readonly"
          @keyup.enter="onSave"
        /> -->

        <VDatePicker
          v-model="v.completion_deadline.$model"
          :label="$t('app.completion_deadline')"
          :errors="v.completion_deadline.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />
      </div>

      <div class="grid grid-cols-2 gap-x-8 gap-y-6 mb-6">
        <VDatePicker
          v-if="hasRegistrationRequestedOn"
          v-model="v.registration_requested_on.$model"
          :label="$t('app.registration_requested_on')"
          :errors="v.registration_requested_on.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VSelect
          v-if="hasRegistrationRequestedBy"
          v-model="v.registration_approved_by.$model"
          :label="$t('app.registration_approved_by')"
          :errors="v.registration_approved_by.$errors"
          :options="options.registration_approved_by"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VDatePicker
          v-if="isRegistered"
          v-model="v.registered_on.$model"
          :label="$t('app.registered_on')"
          :errors="v.registered_on.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VDatePicker
          v-if="isInProgress"
          v-model="v.started_on.$model"
          :label="$t('app.started_on')"
          :errors="v.started_on.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VDatePicker
          v-if="hasRegistratonCompletedOn"
          v-model="v.completion_approval_requested_on.$model"
          :label="$t('app.completion_approval_requested_on')"
          :errors="v.completion_approval_requested_on.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VSelect
          v-if="hasRegistrationCompletedBy"
          v-model="v.completion_approved_by.$model"
          :label="$t('app.completion_approved_by')"
          :errors="v.completion_approved_by.$errors"
          :options="options.completion_approved_by"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VDatePicker
          v-if="value.status === ALL_OPTIONS.COMPLETED.value"
          v-model="v.completed_on.$model"
          :label="$t('app.completed_on')"
          :errors="v.completed_on.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VSelect
          v-if="hasResult"
          v-model="v.result.$model"
          :label="$t('app.result')"
          :errors="v.result.$errors"
          :options="options.result"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VDatePicker
          v-if="value.status === ALL_OPTIONS.CANCELLED.value"
          v-model="v.cancelled_on.$model"
          :label="$t('app.cancelled_on')"
          :errors="v.cancelled_on.$errors"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <VSelect
          v-if="!isCreate"
          :model-value="roleValues"
          :label="$t('app.roles', 2)"
          multiple
          readonly
          class="col-span-2"
          :options="roleOptions"
        />

        <VFile
          v-model="v.certificate_file_id.$model"
          :label="$t('app.certificate_file_id')"
          :errors="v.certificate_file_id.$errors"
          :options="options.certificate_file_id"
          :readonly="readonly"
          @keyup.enter="onSave"
        />

        <div v-if="!isCreate" />

        <VSwitch
          v-else
          v-model="v.required_for_role.$model"
          :label="$t('app.required_for_role')"
          true-value="Yes"
          false-value="No"
          :errors="v.required_for_role.$errors"
        />

        <VWysiwyg
          v-if="!isCreate"
          v-model="v.completion_comments.$model"
          :label="$t('app.completion_comments')"
          :errors="v.completion_comments.$errors"
        />

        <VWysiwyg
          v-if="!isCreate"
          v-model="value.learner_comments"
          readonly
          :label="$t('app.learner_comments')"
        />
      </div>
    </template>
  </FormCard>
</template>

<script>
import { onMounted, watch } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useRegistrationCollection from "@/composables/useRegistrationCollection";
import useRegistrationForm from "@/composables/useRegistrationForm";
import useOptions from "@/composables/useOptions";
import useRequest from "@/composables/useRequest";
// Mixins
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  emits: ["click:save"],
  setup(props, context) {
    // Composables
    const { request } = useRequest();
    const { ALL_OPTIONS } = useOptions();
    const { value, rules, options, getOptions } = useRegistrationCollection();
    const { v, onCancel, onUpdate, isActive, onSave, isCreate } = useForm(
      props,
      context,
      {
        value,
        rules
      }
    );
    const {
      registeredViaOptions,
      roleOptions,
      roleValues,
      hasRegistratonCompletedOn,
      hasRegistrationCompletedBy,
      hasRegistrationRequestedOn,
      hasRegistrationRequestedBy,
      isInProgress,
      isRegistered,
      hasResult,
      isRegistrationRequested,
      isCompletionApprovalRequested
    } = useRegistrationForm(props, value, options, isCreate);

    // Methods
    const updateStatus = async () => {
      if (!isCreate.value) {
        return;
      }

      if (!value.value.collection_id) {
        return;
      }

      const response = await request({
        endpoint: "teaching.collections.read",
        pathParams: {
          id: value.value.collection_id
        },
        showToaster: false
      });

      const data = response?.payload?.data[0] ?? {};

      if (data.requires_registration_approval === ALL_OPTIONS.YES.value) {
        value.value.status = ALL_OPTIONS.REGISTRATION_REQUESTED.value;
      }

      if (data.requires_registration_approval === ALL_OPTIONS.NO.value) {
        value.value.status = ALL_OPTIONS.PENDING.value;
      }
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    // Watch
    watch(
      () => value.value.collection_id,
      async () => {
        await updateStatus();
      }
    );

    return {
      value,
      // useRegistrationForm
      registeredViaOptions,
      roleValues,
      roleOptions,
      // useReigstrationForm
      hasRegistratonCompletedOn,
      hasRegistrationCompletedBy,
      hasRegistrationRequestedOn,
      hasRegistrationRequestedBy,
      isInProgress,
      isRegistered,
      hasResult,
      isRegistrationRequested,
      isCompletionApprovalRequested,
      // useOptions
      ALL_OPTIONS,
      // useForm
      onCancel,
      isActive,
      v,
      options,
      onUpdate,
      onSave,
      isCreate
    };
  }
};
</script>
